import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IntegrationComponent } from './integration/integration.component';
import { UserComponent } from './user/user.component';

const routes: Routes = [{
  path: '', component: UserComponent
}, {
  path: 'integration', component: IntegrationComponent
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
