import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-integration',
  templateUrl: './integration.component.html',
  styleUrls: ['./integration.component.scss']
})
export class IntegrationComponent implements OnInit {

  first = `
  <!-- import the dependencies -->
  <script src="#{ziwo_url}/ziwo-dialpad.js" charset="utf-8"></script>
  <!-- declare the dialpad -->
  <ziwo-dialpad></ziwo-dialpad>
  `;

  second = `
  <script src="#{ziwo_url}/ziwo-dialpad.js" charset="utf-8"></script>
  <!--
    @onevents: register a call back function for Ziwo Events (see Ziwo Events below)
    @autorun: [default true] dialpad will automatically starts when ready. (see Ziwo Event for an example)
    @mode: set the dialpad mode. Possible values are 'full', 'standard', 'mini' or 'micro' (see Dialpad types section)
    @contactcentername: if you want to automatically log in, you need to provide the contact center to use
    @authenticationtoken: you can automatically log in the user by providing an authentication token
    @agentemail: you can automatically log in the user by providing agent's email + password
    @agentpassword: you can automatically log in the user by providing agent's email + password
  -->
  <ziwo-dialpad
    onevents="ziwoEventCallbackFunc"
    autorun="true"
    mode="full"
    contactcentername="kalvad-poc"
    authenticationtoken="{token_provided_by_ziwo_api}"
    agentemail="{current_agent_email}"
    agentpassword="{current_agent_password}"
  ></ziwo-dialpad>
  `;

  third = `
  <script type="text/javascript">
    function ziwoEvents(ev) {
      console.log('New Ziwo event => ', ev);
      switch (ev.type) {
        case 'dialpad_ready':
          startZiwoDialpad();
          break;
        case 'dialpad_started':
          console.log('Dialpad has started');
          break;
        default:
          console.log('Unhandled Ziwo Event => ', ev);
      }
    }
  </script>

  <script src="dist/ziwo-dialpad-web-component/ziwo-dialpad.js" charset="utf-8"></script>

  <ziwo-dialpad onevents="ziwoEvents" autorun="false"></ziwo-dialpad>
  `;

  forth = `
  <script type="text/javascript">
  /**
   * You can directly interact with the client through 'window.ZIWO.client'.
   */

  // simply start a call
  function startCall(num) {
      window.ZIWO.client.startCall(num);
  }

  // logout the agent
  function stop() {
      window.ZIWO.client.disconnect();
  }

  /**
   * You can also listen for ziwo events and perform any actions
   */
  window.addEventListener('ziwo-ringing', (ev:CustomEvent) => {
      // you can access the 'call' instance in the event's detail
      const call = ev.detail.currentCall;
      // current call is a call instance (see https://core-front.ziwo.io/classes/call.html)
      call.answer();
  });

  // Here is the full list of available events
  window.addEventListener('ziwo-ringing', (ev:CustomEvent) => this.onRinging(ev));
  window.addEventListener('ziwo-hangup', (ev:CustomEvent) => this.onHangup(ev));
  window.addEventListener('ziwo-early', (ev:CustomEvent) => this.onEarly(ev));
  window.addEventListener('ziwo-active', (ev:CustomEvent) => this.onActive(ev));
  window.addEventListener('ziwo-connected', (ev:CustomEvent) => this.onConnected(ev));
  window.addEventListener('ziwo-disconnected', (ev:CustomEvent) => this.onDisconnected(ev));
  window.addEventListener('ziwo-recovering', (ev:CustomEvent) => this.onRecovering(ev));
  window.addEventListener('ziwo-destroy', (ev:CustomEvent) => this.onDestroy(ev));
  window.addEventListener('ziwo-error', (ev:CustomEvent) => this.onError(ev));
</script>

<script src="dist/ziwo-dialpad-web-component/ziwo-dialpad.js" charset="utf-8"></script>
<ziwo-dialpad autorun="false"></ziwo-dialpad>
  `;

  constructor() { }

  ngOnInit(): void {
  }

}
