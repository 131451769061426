<div id="doc">
  <h1>ZIWO Dialpad Documentation</h1>

  <p>
    Our Dialpad is the core of our Telecommunication system. It is a small applet that is available across all
    applications relying on ZIWO to perform phone calls.
  </p>

  <p>
    Depending on your usage, Dialpad can have different formats but this is what the standard design looks like:
  </p>

  <div class="center">
    <img src="/assets/standard.png" alt="ziwo dialpad home">
  </div>

  <p>
    The home page of the Dialpad allow you to quickly type a phone number. But there are few things to note:

  </p>
  <ul>
    <li>You can also type a phone number using your laptop's keyboard</li>
    <li>You can start a call by using the 'enter' key on your laptop's keyboard</li>
    <li>You can directly type the country phone number's prefix to automatically change the selected country</li>
    <li>You can only start a call with a valid international phone number</li>
  </ul>
  <p>Let's try all of this:</p>
  <div class="center">
    <iframe id="video1" type="text/html" style="height:420px;width: 640px;"
      src="https://www.youtube.com/embed/m_g1-2wHGxo?autoplay=0&origin=https://doc-dialpad.ziwo.tech"
      frameborder="0"></iframe>
  </div>

  <h2>Dialpad Formats</h2>
  <p class="intro">
    In order to adapt to all the scenarios, the Dialpad is available in 5 different formats. Each format provides a
    different list of features.
  </p>
  <!-- <p>
    For each feature explained in this documentation, we will also attached the Dialpad format that supports or do not
    support said feature.
  </p> -->

  <h3>Full</h3>
  <div class="center">
    <img src="/assets/full.png" alt="ziwo format - full" />
  </div>
  <p>"Full" format provides all the available features. It is usually the one used for integration
    (ZenDesk, Zoho, etc...).</p>

  <h3>Standard</h3>
  <div class="center">
    <img src="/assets/standard.png" alt="ziwo format - standard" />
  </div>
  <p>"Standard" format provides the core feature the calling system only.</p>


  <h3>In app</h3>
  <div class="center">
    <img src="/assets/inapp.png" alt="ziwo format - inapp" />
  </div>
  <p>"In app" is a handmade format available only through the official ZIWO application.</p>


  <h3>Mini</h3>
  <div class="center">
    <img src="/assets/mini.png" alt="ziwo format - mini" />
  </div>
  <p>"Mini" is a single input that allow you to start a phone call.</p>


  <h3>Micro</h3>
  <div class="center">
    <img src="/assets/micro.png" alt="ziwo format - micro" />
  </div>
  <p>"Micro" only allow you to receive phone call.</p>

  <h2>Dictionary</h2>

  <p>
    Before going further, let's go through the technical words:
  </p>
  <ul>
    <li><strong>Inbound call (or incoming call)</strong> - calls started by your recipient therefore ringing on your
      end.</li>
    <li><strong>Outbound call (or outgoing call)</strong> - calls you started therefore ringing on your recipient's end
    </li>
    <li><strong>Queue</strong> - list of agent assigned to a same phone number. Incoming calls will be dispatched
      between
      available agents assign to said queue.</li>
    <li><strong>Selected country</strong> - the selected country is the country that will be used to validate the
      international format of a phone number.</li>
    <li><strong>Direct transfer</strong> - transfer your current call to another number.</li>
    <li><strong>Attended transfer</strong> - transfer your current call to another number but allow you to talk to the
      third leg of the call before completing the transfer.</li>
    <!-- <li><strong></strong></li> -->
  </ul>

  <h2>Login</h2>
  <p>Unless you are in a scenario where you are automatically logged in, you will first have to log into the system. To
    do so, you will need your credentials but also your accout name. Check out your email to know your account name.</p>

  <h2>First setup</h2>
  <p>Once authenticated, the dialpad will be instantly ready. However, if it is the first time you start the Dialpad, we
    suggest you to go on Settings and to configure your default selected country. See below:
  </p>
  <div class="center">
    <img src="/assets/setup.png" alt="change default country" />
  </div>

  <h2>Starting a call</h2>
  <p>
    Starting a call is easy. Once you entered a valid international phone number, you can simply using the 'call' button
    or the enter key. However, if the phone number is not a valid international phone number, you will not be able to
    start the call.
  </p>
  <p>If you are not sure about the international format of a specific country, let the Dialpad help you. You can
    select the country you want to call to and start typing a local phone number. Once the phone number is complete,
    the dialpad will automatically edit the local phone number to the international version of the number. You can then
    start the call.</p>
  <div class="center">
    <div style="display: flex">
      <img src="/assets/country-change-1.png" alt="change country format" />
      &nbsp;
      <img src="/assets/country-change-2.png" alt="change country format" />
    </div>
    <br />
    <div style="display: flex">
      <img src="/assets/country-format-1.png" alt="change country format" />
      &nbsp;
      <img src="/assets/country-format-2.png" alt="change country format" />
    </div>
  </div>

  <p>Once the call has started and while the call is ringing on your recipient's end, the dialpad will display any
    information available. If no one answers the call, the dialpad will get back to it's home page. If someone does
    answer, you will be redirected to the 'in call' screen.</p>

  <div class="center">
    <img src="/assets/early.png" alt="early screen" />
  </div>

  <h2>Receiving a call</h2>

  <p>
    Receiving an incoming call is pretty straightforward and will make your Dialpad ring. If you enabled notifications,
    you will also receive a notification with the calls information. You can click on the notification to get back on
    the Dialpad.
  </p>

  <h2>While on call</h2>

  <p>The active call screen provide all information regarding the current calls as well as a quick access to some
    handful actions:</p>
  <ul>
    <li>You can easily mute/unmute the current call.</li>
    <li>You can easily hold/unhome the current call.</li>
    <li>You can start a transfer</li>
    <li>You can open the dialpad to type any number - useful for DTFM</li>
  </ul>
  <h3>Call transfer</h3>
  <p>Call transfer allow you to forward the current call to someone else.</p>
  <h4>Direct transfer</h4>
  <p>Direct tranfer will stop your current call and instantly create a new call between your current recipient and the
    phone number you selected.</p>
  <p>To start a direct transfer, first click on 'transfer' and then either select a Contact (see contacts below for more
    information) or type a phone number. Once you made your choice click on 'direct transfer' to complete the transfer.
  </p>
  <h4>Attended transfer</h4>
  <p>Attended transfer add an extra step to a direct transfer. Indeed, it first create a call between you and the phone
    number you want to forward the current call.</p>
  <p>To start an attended transfer, first click on 'transfer' and then either selection a Contact or type a valid phone
    number. You can now click on 'attended transfer'. Your current call will be set on hold and you will be connected to
    the contact or phone number you previously typed. You can click on 'confirm attended transfer' to complete the
    transfer. The first call will stop and a new a call between your previous and your new recipients will start.</p>

  <h3>End of call survey</h3>
  <p>If an end of call survey is available for the current call, you will be able to enable or disable it. It enabled,
    at the end of the current call, your recipient will be forwarded to a survey automatic system.
    You can change the language used during the survey by clicking on the survey icon. The selected language will appear
    below the icon.</p>

  <h3>Running multiple calls</h3>
  <p>You can have multiple calls running inside the dialpad. However, only one of the calls can be active. All other
    calls
    will be set on hold.
    If you have more than one call running, the dialpad will display all your current peers and highlight the call
    currently active. You can easily switch from one call to another by clicking on the peers' pictures.
  </p>
  <p>At the end of your current call, and if you still have more than one call, you will get redirected to a "call
    picker"
    screen. This screen presents you all the calls currenly on hold and you can chose which call to resume.</p>

  <h3>Demo of all the features available during active call</h3>

  <div class="center">
    <iframe id="video1" type="text/html" style="height:420px;width: 640px;"
      src="https://www.youtube.com/embed/_-Q-m33Qdps?autoplay=0&origin=https://doc-dialpad.ziwo.tech"
      frameborder="0"></iframe>
  </div>

  <h2>Settings</h2>

  <h3>Profile</h3>

  <div class="center">
    <img src="/assets/profile.png" alt="profile" />
    <br />
    <img src="/assets/bar-settings.png" alt="profile bar" />
  </div>

  <p>Profile page allow you to configure the usage of the dialpad. From there, you can configure the language used by
    the
    Dialpad and also the default country. We recommend you set this value to the country you most often call to.</p>

  <h3>Sounds & Devices</h3>

  <div class="center">
    <img src="/assets/sounds.png" alt="profile" />
    <br />
    <img src="/assets/bar-settings.png" alt="profile bar" />
  </div>

  <p>Sounds & Devices lets you configure the devices used during your calls. It also provides tools to test your
    configuration.</p>

  <ul>
    <li>Speakers - by clicking on this input, you can select the output used during your calls</li>
    <li>Play a sound test - click on the play icon to test the sounds output of the Dialpad</li>
    <li>Volume - you can adjust the volume of the Dialpad by using the scroll bar.</li>
    <li>Microphone - by clicking on this input, you can select the input used during your calls</li>
    <li>Input test bar - the last item of this screen all you to test the sounds detected by your microphone. If you the
      bar reacts when you talk, you are good to go !</li>
  </ul>

  <h3>Device Troubleshooter</h3>

  <p>Please note in case of invalid configuration, the dialpad will show a red icons on the main screen. You can click
    on the icon to directly open the Sounds and Devices screen.</p>

  <h2>Call History</h2>

  <div class="center">
    <img src="/assets/bar-history.png" alt="history bar" />
  </div>

  <p>Call history allow you to look through your previous call records. You can also start a call directly from there.
    By default, you will see all the call made this day.</p>

  <p>Move your cursor over a row to display the actions buttons. From there, you can call back the phone number and you
    can also listen to the call recording if it is available.</p>
  <div class="center">
    <img src="/assets/history-actions.png" alt="history actions" />
  </div>

  <p>
    You can use the tabs on the top to filter the calls by Inbound, Outbound or Missed. By default, all calls are
    displayed.
  </p>

  <h3>Search in the history</h3>
  <p>
    By default, the screen display all the calls of the day. But if you need to navigate through the past days, you can
    use the left and right arrows to display the calls from the past days.
    If you went too far and want to get back at the default screen, you can use 'show latest' button to reset the
    current navigation.
  </p>
  <div class="center">
    <img src="/assets/history-arrows.png" alt="history arrows" />
  </div>

  <p>If you are looking for older call records, you can use the 'Filters' button. From there, you will be able to select
    a longer time range. You can for example display all the calls for a whole week. And once again use the arrows to
    navigate from one week to another. You can also use a custom time range if you are looking for a specific call
    record.</p>
  <div class="center">
    <img src="/assets/history-buttons.png" alt="history actions" />
  </div>
  <br />
  <div class="center">
    <img src="/assets/history-range.png" alt="history actions" />
  </div>



  <h2>Contacts</h2>
  <div class="center">
    <img src="/assets/bar-contacts.png" alt="contact bar" />
  </div>
  <p>
    Contacts page allow you to quickly call your team members. If you need to call another agent or a queue, look no
    further, this is where you need to be.
    Please note this screen is same used during call transfer.
  </p>

  <p>
    Here, you can either search for an agent or for a queue. Both tabs work the same way:
  </p>
  <ul>
    <li>You can use the top input to search for a specific agent or queue</li>
    <li>You can use the 'available' toggle to display only connected agents or all agents</li>
    <li>You can use the 'call' button available by moving your cursor hover a row to call the selected agent or queue
    </li>
  </ul>

  <div class="center">
    <img src="/assets/call-agent.png" alt="call from contact" />
  </div>

  <h2>Status</h2>
  <div class="center">
    <img src="/assets/bar-status.png" alt="status bar" />
    <br />
    <img src="/assets/status.png" alt="status picker" />

  </div>

  <p>Status management is only available for the 'full' dialpad.</p>
  <p>You can change your current status by using the status picker. The status will automatically change the color of
    the dialpad. Your dialpad's colors refects your current status:
  </p>

  <ul>
    <li>Green: Available</li>
    <li>Orange: On a break</li>
    <li>Brown: In a meeting</li>
    <li>Gray: offline</li>
  </ul>

  <h2>Call roaming</h2>
  <p>Call roaming is very useful when you are not near your computer but still need to receive calls.</p>
  <p>If call roaming has been properly setup and enabled, all incoming calls will automatically ring on your own phone.
  </p>
  <p>If call roaming has been properly setup and enabled, when starting a new call, the call will stop on the dialpad
    and will be forwarded directly to your own phone.</p>

</div>

<div class="footer">
  <div class="content">
    Please feel free to contact our support if you face any problem.
  </div>
</div>