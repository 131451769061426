<div id="doc">
  <h1>ZIWO Dialpad - Integration</h1>
  <p>The ZIWO Dialpad web component allow you to quickly implement a Dialpad.</p>
  <h2>Requirements</h2>
  <p>Before starting the integration, you need to contact the Ziwo team to receive a staging environment URL as well as
    a testing account.</p>
  <p><em>Note: please notify us when your integration is ready so we can provide you a production URL</em></p>

  <h2>Run a simple web component</h2>
  <p>Building a Ziwo Dialpad is as simple as creating a DIV element:</p>

  <pre [innerText]="first"></pre>

  <p>This create a single dialpad and let you start or receive calls, check call history, etc...</p>
  <h2>Dialpad parameters</h2>
  <p>You can change the default behavior of the dialpad. Here is an example with all the parameters used.</p>

  <pre [innerText]="second"></pre>
  <p><em>You can cherry pick the parameters you need.</em></p>
  <h2>Dialpad types</h2>
  <p>We have different types of dialpad available:</p>
  <ul>
    <li><strong>full</strong> provides all the features: start call, receive call, country picker, change status,
      dialpad settings, call history, contact</li>
    <li><strong>standard</strong> provide all the feature but the ones related to status and call history: start call,
      receive call, country picker, dialpad settings, contact</li>
    <li><strong>mini</strong> provides a single input to start a call. You can also receive a call. On call actions are
      the same than full or standard
      <ul>
        <li><strong>micro</strong> only allow to receive a call. On call actions are the same than full or standard</li>
      </ul>
    </li>
  </ul>
  <h2>Ziwo Events</h2>
  <p>You can track all Ziwo events by registering a callback function. See below:</p>
  <pre [innerText]="third"></pre>

  <h2>Go further with ziwo-core-front</h2>
  <p>Ziwo dialpad wraps our ziwo-core-front library (publicly available at <a
      href="https://github.com/ASWATFZLLC/ziwo-core-front">https://github.com/ASWATFZLLC/ziwo-core-front</a> &amp;
    documentation at <a href="https://doc-core-front.ziwo.tech/">https://doc-core-front.ziwo.tech/</a>).</p>
  <p>If you need more control over Ziwo, or if you want to implement specific feature, you can directly access the
    ziwo-core-front client through the window once the user is authenticated.</p>
  <p>Here is an example of what action you can perform using the client. However, we suggest reading the documentation
    linked previously to see the full potential of the Ziwo client.</p>
  <p><em>Action started through the Ziwo client will automatically reflect on the Dialpad</em></p>
  <pre [innerText]="forth"></pre>

</div>

<div class="footer">
  <div class="content">
    Please feel free to contact our support if you face any problem.
  </div>
</div>